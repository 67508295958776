
import React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { useCurrentUser } from '../../utils/CurrentUserContext';
import { bridgeDays } from '../../Settings';
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import moment from 'moment';

import {
    datesAreOnSameDay,
    getPublicHolidaysEvents,
    dateDiffToMinutes,
    minutesToHoursMinutes,
    getExpectedWorkingMinutes,
    getWeekDay,
} from '../../utils/config';

import './config/calendar.css'


const Calendar = ({
    records,
    handleShowModal,
    handleEventResize,
    fetchMoreFunc,
    setExpectedWorkingMinutes,
    setDailyMinutesWorked,
    fromHR,
}) => {
    const { currentUser } = useCurrentUser()
    const myEvents = records.map(e => ({
        start: e.start,
        end: e.end,
        title: e.project.name,
        id: e.id,
        color: e.project.category.color,
        extendedProps: {
            description: e.description,
            projectId: e.project.id,
            projectName: e.project.name,
        },
    }))

    const publicHolidays = getPublicHolidaysEvents()
    bridgeDays.forEach(day => {
        publicHolidays.push({
            start: moment(day).toDate(),
            title: 'Bridge day',
            allDay: true,
            color: "#a8326d",
            editable: false,
            startEditable: false,
        })
    })


    // Join user records with public holidays
    const events = myEvents.concat(publicHolidays)

    const calendarRef = React.createRef();

    const getCurrentDateView = () => {
        const data = sessionStorage.getItem('currentDateView')
        return data ? data : moment().format("YYYY-MM-DD")
    }
    const getCurrentView = () => {
        const data = sessionStorage.getItem('currentView')
        return data ? data : 'timeGridWeek'
    }

    const handleSelect = async ({ start, end, allDay }) => {
        if (end.getHours() === 0 && end.getMinutes() === 0) {
            end.setMinutes(end.getMinutes() - 1);
        }

        setDailyMinutesWorked(getSumMinutesWorked(start))
        setExpectedWorkingMinutes(getExpectedWorkingMinutes(currentUser.userprofile.workSchedule, getWeekDay(start)))

        if (allDay) {
            end = new Date(start)
            // if day is a weekend set to friday
            const daysOfWeek = ['Fri', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Fri'];
            const dayName = daysOfWeek[start.getDay()];
            if (Object.keys(currentUser.userprofile.workSchedule[dayName]).length === 0) {
                start.setHours(9)
                end.setHours(16)
                end.setMinutes(36)
            }
            else {
                const [startHours, startMinutes] = currentUser.userprofile.workSchedule[dayName].start_time.split(':');
                const [endHours, endMinutes] = currentUser.userprofile.workSchedule[dayName].end_time.split(':');

                start.setHours(parseInt(startHours));
                start.setMinutes(parseInt(startMinutes));
                end.setHours(parseInt(endHours));
                end.setMinutes(parseInt(endMinutes));
            }
        }

        handleShowModal({
            start,
            end,
            allDay,
            newRecord: true
        })
    }

    // Move or resize a record
    const handleEventChange = (e) => {
        const { event } = e
        handleEventResize({
            start: event.start,
            end: event.end,
            description: event.extendedProps.description,
            projectId: event.extendedProps.projectId,
            state: 'active',
            id: event.id
        })
    }

    // Edit an existing record
    const handleEventClick = (e) => {
        // Open a modal <EventModal /> to edit the selected event
        const rec = {
            start: e.event.start,
            end: e.event.end,
            description: e.event.extendedProps.description,
            projectId: e.event.extendedProps.projectId,
            projectName: e.event.extendedProps.projectName,
            id: e.event.id,
            newRecord: false
        }

        handleShowModal(rec)
    }


    // TODO: update setExpectedWorkingMinutes and setDailyMinutesWorked when select a new date

    const getSumMinutesWorked = (day) => {
        const sumTotalMinutes = events
            .filter(event => datesAreOnSameDay(new Date(event.start), day))
            .reduce((acc, event) => {
                const diffMins = dateDiffToMinutes(new Date(event.start), new Date(event.end))
                return acc + diffMins
            }, 0)
        return sumTotalMinutes
    }

    // Add custom text in event
    const renderEventContent = (eventInfo) => {
        const diffMins = dateDiffToMinutes(eventInfo.event.start, eventInfo.event.end)
        const title = eventInfo.event.title.length < 16 ? eventInfo.event.title : eventInfo.event.title.slice(0, 16) + "..."
        const result = minutesToHoursMinutes(diffMins)

        return (
            <div style={!title ? { position: 'absolute', bottom: 0, left: 0 } : {}}>
                {/* <b>{eventInfo.timeText}</b> */}
                <b>{result} </b>
                <i>{title}</i>
            </div>
        )
    }

    return (<>
        <Box display="flex" justifyContent="center" marginBottom="10px" sx={{ mb:2 }}>
            <Button variant="contained" color="primary" onClick={() =>
                handleShowModal({
                    allDay: false,
                    newRecord: true,
                    dateTimeRecord: true,
                })
        }>Create Record</Button>
        </Box>
        <div style={{ height: '85vh', overflow: 'auto' }}>
            <FullCalendar
                height='100%'
                aspectRatio={1}
                timeZone='Europe/Brussels'
                plugins={[momentTimezonePlugin, timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                // height="auto"
                ref={calendarRef}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridWeek,timeGridDay,listWeek'
                }}
                views={!fromHR &&
                {
                    timeGridDay: {
                        dayHeaderContent: (date) => {
                            // return a int representing the total minutes of the displayed day
                            const sumTotalMinutes = getSumMinutesWorked(date.date)
                            setDailyMinutesWorked(sumTotalMinutes)

                            const expectedWorkingMinutes = getExpectedWorkingMinutes(currentUser.userprofile.workSchedule, getWeekDay(date.date))
                            setExpectedWorkingMinutes(expectedWorkingMinutes)

                            const timeToGo = minutesToHoursMinutes(expectedWorkingMinutes - sumTotalMinutes)
                            const fullDay = () => (sumTotalMinutes == expectedWorkingMinutes)
                            const overTime = () => (sumTotalMinutes > expectedWorkingMinutes)

                            if (fullDay()) {
                                return `Day completed!`
                            }
                            else if (overTime()) {
                                return `Overtime: ${minutesToHoursMinutes(sumTotalMinutes - expectedWorkingMinutes)}`
                            }
                            else if (timeToGo != '') {
                                return `${timeToGo} left`
                            }
                            else {
                                return `Not working today`
                            }
                        },
                        titleFormat: { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' },
                    }
                }
                }
                firstDay={1}
                locale="en-gb"
                initialView={getCurrentView()}
                initialDate={getCurrentDateView()}
                eventColor='#B39FF0'
                slotDuration='00:15:00'
                slotEventOverlap={false}
                // slotMinTime="06:00:00"
                // slotMaxTime="23:59:00"
                scrollTime='09:00:00'
                nowIndicator={true}
                // weekends={false}
                // dayHeaderContent={(date) => moment(date.date).format("ddd D")}

                events={events}
                eventOverlap={false}
                eventContent={renderEventContent}
                editable={true}
                selectable={true}
                selectMirror={true}
                eventClick={(handleEventClick)}
                select={handleSelect}
                // Disable the select for public holidays
                // TODO allow users to block public holidays
                // selectAllow={selectInfo => publicHolidays.filter(holiday => datesAreOnSameDay(holiday.start, selectInfo.start)).length == 0}
                //
                dayCellClassNames={e => {
                    if ((publicHolidays.filter(holiday => datesAreOnSameDay(holiday.start, e.date)).length !== 0) ||
                        ([0, 6].includes(e.date.getDay()))) {
                        return ['publicHoliday']
                    }
                }}
                eventChange={handleEventChange}
                datesSet={dateInfo => {
                    sessionStorage.setItem('currentView', dateInfo.view.type)
                    sessionStorage.setItem('currentDateView', moment(dateInfo.start).format("YYYY-MM-DD"))
                    //check if the view date is in the current week
                    if (!(moment().isoWeek() == moment(dateInfo.start).isoWeek())) {

                        fetchMoreFunc(dateInfo.start, dateInfo.end)
                    }
                }
                }

            />
        </div>
    </>
    )
}

export default Calendar;